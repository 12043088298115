/*ROOT STYLES*/

:root {
  --color-primary: #d53e4a;
  --color-primary--light: #fcdfe2;
  --color-secondary: #1e7398;
  --color-secondary-15: #1e739833;
  --color-grey--light: #35383c;
  --color-grey--dark: #282b2e;

  --breakpoint-mobile: 576px;
}

/* Add font face for different styles */
@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Italic-VariableFont_wght.ttf");
  font-style: italic;
}

/*BASE STYLES*/

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: 400;
}

.App {
  font-size: 1.6rem;
  font-family: "Montserrat";
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/*Form styles*/

.list--sh li.MuiListItem-root.MuiListItem-gutters {
  height: 8rem;
}
